import React from "react"
import H3 from "./Utility/heading3"
import Button from "./Utility/button"
import { Col, Row } from "reactstrap"

export default props => (
  <Col
    id={props.idName}
    className={`cta--centered p-5 ${props.twoColumn ? "col-12 col-lg-6" : ""} ${
      props.order ? props.order : ""
    }`}
    style={{
      backgroundImage: `url(${props.bgImage})`,
      marginBottom: props.ctaBottomMargin,
    }}
  >
    <div className="cta--centered__content text-center mx-auto">
      <img className="badge py-4" src={props.badgeSrc} />
      <H3
        className="py-4"
        headingText2={props.headingText2}
        headingColor2={props.headingColor2}
      />
      <p className="cta-body py-4" style={{ color: props.bodyColor }}>
        {props.bodyText}
      </p>
      {props.buttonText && (
        <Button
          className="my-4"
          buttonLink={props.buttonLink}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
          buttonBGColor={props.buttonBGColor}
        />
      )}
      {props.footnoteText && (
        <p className="note py-4" style={{ color: props.footnoteColor }}>
          {props.footnoteText}
        </p>
      )}
    </div>
  </Col>
)
