import React from "react"
import Slider from "react-slick"
import Eyebrow from "./Utility/eyebrow"
import Button from "./Utility/button"
import Review from "./Review/Review"
import { Row, Col } from "reactstrap"

export default props => (
  <Row className={`cta-full-width no-gutters ${props.ctaClass}`}>
    <Col
      id={props.idName}
      className="px-5 mb-5 col-12 col-lg-5 order-lg-2 align-self-lg-center"
      style={{ backgroundColor: props.ctaBGColor }}
    >
      {/*<Eyebrow eyebrowText={props.eyebrowText} eyebrowColor={props.eyebrowColor} />*/}
      <h3 className="mb-0" style={{ color: props.headingColor1 }}>
        <span>{props.headingText1}</span>
      </h3>
      <h3 className="mb-0" style={{ color: props.headingColor2 }}>
        {props.headingText2}
      </h3>
      <p className="cta-body m-0 pb-5 pt-5" style={{ color: props.bodyColor }}>
        {props.bodyText}
      </p>
      {/*
            <div class="product-items" style={{ color: props.itemColor }}>
                <Review slideReviewID={props.slideReviewID} initialSlide={props.initialSlide} />
            </div>
            */}
      {props.buttonText && (
        <Button
          buttonLink={props.buttonLink}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
          buttonBGColor={props.buttonBGColor}
        />
      )}
      {/*
                props.footnoteText && (<p class="note" style={{ color: props.footnoteColor }}>{props.footnoteText}</p>)
            */}
    </Col>
    <Col className={"media px-4 col-12 col-lg-7 order-lg-1"}>
      {/* <a href={props.buttonLink}> */}
      <img src={props.imgSrc} alt={props.altText} className="w-100" />
      {/* </a> */}
    </Col>
  </Row>
)
