import React from "react"
import Slider from "react-slick"
import Eyebrow from "./Utility/eyebrow"
import H1 from "./Utility/heading1"
import H3 from "./Utility/heading3"
import Button from "./Utility/button"
import { BVLoader, BVSummary } from "@tyson-foods/react-component-library"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Col, Row } from "reactstrap"

const settings = {
  arrows: true,
  dots: true,
  autoplay: true,
  autoplaySpeed: 4000,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default props => (
  <Row
    className={`hero producthero mx-0 ${props.heroDirection} ${props.heroClass}`}
  >
    <BVLoader
      clientName="raisedandrooted"
      siteID="main_site"
      env={process.env.GATSBY_BAZAARVOICE_ENV}
    />

    <Col className="productHero__image col-12 px-0 col-md-6">
      {props.children}
      {/* <Slider {...settings}>{props.children}</Slider> */}
    </Col>
    <Col
      className="productHero__content col-12 pt-5 col-md-6"
      style={{ backgroundImage: `url(${props.bgImage})` }}
    >
      <H1
        className="product__title pt-md-5 mt-4"
        headingText1={props.headingText1}
        headingColor1={props.headingColor1}
        headingTitleText1={props.headingTitleText1}
        headingTitleColor1={props.headingTitleColor1}
      />
      {(props.subheadingText1 || props.subheadingText2) && (
        <H3
          headingText1={props.subheadingText1}
          headingText2={props.subheadingText2}
          headingColor1={props.subheadingColor1}
          headingColor2={props.subheadingColor2}
        />
      )}
      <div class="review-summary">
        {props.productID && <BVSummary productID={props.productID} />}
        {props.productID && (
          <a href="#reviews" class="see-all-link">
            See all
          </a>
        )}
      </div>
      <p className="hero-body" style={{ color: props.bodyColor }}>
        {props.bodyText}
      </p>

      <Row className="product-items" style={{ color: props.itemColor }}>
        <Col className="data-item col-12 col-md-4">
          {props.dataImg1 && <img src={props.dataImg1} />}
          {props.valueText1 && (
            <span className="data-value">{props.valueText1}</span>
          )}
          {props.unitText1 && (
            <span className="data-unit">{props.unitText1}</span>
          )}
          {props.labelText1 && (
            <span className="data-label">{props.labelText1}</span>
          )}
        </Col>
        <Col className="data-item col-12 col-md-4">
          {props.dataImg2 && <img src={props.dataImg2} />}
          {props.valueText2 && (
            <span className="data-value">{props.valueText2}</span>
          )}
          {props.unitText2 && (
            <span className="data-unit">{props.unitText2}</span>
          )}
          {props.labelText2 && (
            <span className="data-label">{props.labelText2}</span>
          )}
        </Col>

        <Col className="data-item col-12 col-md-4">
          {props.dataImg3 && <img src={props.dataImg3} />}
          {props.valueText3 && (
            <span className="data-value">{props.valueText3}</span>
          )}
          {props.unitText3 && (
            <span className="data-unit">{props.unitText3}</span>
          )}
          {props.labelText3 && (
            <span className="data-label">{props.labelText3}</span>
          )}
        </Col>
      </Row>
      <Row className="product-items" style={{ color: props.itemColor }}>
        <Col className="data-item col-12 col-md-4">
          {props.dataImg4 && <img src={props.dataImg4} />}
          {props.valueText4 && (
            <span className="data-value">{props.valueText4}</span>
          )}
          {props.unitText4 && (
            <span className="data-unit">{props.unitText4}</span>
          )}
          {props.labelText4 && (
            <span className="data-label">{props.labelText4}</span>
          )}
        </Col>
        <Col className="data-item col-12 col-md-4">
          {props.dataImg5 && <img src={props.dataImg5} />}
          {props.valueText5 && (
            <span className="data-value">{props.valueText5}</span>
          )}
          {props.unitText5 && (
            <span className="data-unit">{props.unitText5}</span>
          )}
          {props.labelText5 && (
            <span className="data-label">{props.labelText5}</span>
          )}
        </Col>
        <Col className="data-item col-12 col-md-4">
          {props.dataImg6 && <img src={props.dataImg6} />}
          {props.valueText6 && (
            <span className="data-value">{props.valueText6}</span>
          )}
          {props.unitText6 && (
            <span className="data-unit">{props.unitText6}</span>
          )}
          {props.labelText6 && (
            <span className="data-label">{props.labelText6}</span>
          )}
        </Col>
      </Row>

      {props.buttonText && (
        <Button
          buttonLink={props.buttonLink}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
          buttonBGColor={props.buttonBGColor}
        />
      )}
      {props.footnoteText && (
        <p className="note" style={{ color: props.footnoteColor }}>
          {props.footnoteText}
        </p>
      )}
    </Col>
  </Row>
)
