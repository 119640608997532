import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"
import Collapsable from "../../components/Collapse/Collapse"
import CTA from "../../components/cta"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>Plant Based Ground Beef</title>
          <meta
            name="description"
            content="Looking for some plant based ground beef? Try our Raised & Rooted™ Plant Based Ground! It's the perfect meatless solution for your favorite recipe or meal."
          />
          <meta itemProp="name" content="Plant Based Ground Beef" />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              headingTitleColor1="#006B2D"
              headingText1="Plant Based Ground"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="What’s in it? Only the most groundbreaking flavor you’ve ever experienced in a 100% plant based ground. It’s juicy, it’s versatile and it’s ready for all your favorite recipes. You really can’t go wrong."
              itemColor="#006B2D"
              valueText1="24"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="1"
              unitText2="g"
              labelText2="Fiber per serving"
              valueText3="75"
              unitText3="%"
              labelText3="Less Saturated Fat*"
              dataImg4="/leaf.svg"
              labelText4="Soy Free"
              dataImg5="/leaf.svg"
              labelText5="Made With Plant Based Protein"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              footnoteText="*Compared to USDA data for 80% Lean/20% Fat Beef"
              footnoteColor="#006B2D"
              productID="00053400000347"
            >
              <img src="/raised-and-rooted-plant-based-ground-708x721.png" alt="Raised And Rooted Plant Based Ground" />
            </Hero>
            <Content
              ctaclassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-plant-based-ground-708x721-ls.png"
              altText="Raised And Rooted Plant Based Ground"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Our Raised & Rooted™ Plant Based Ground is made with pea protein that is seasoned to perfection."
            >
              <h4>NUTRITION</h4>
              <p>
              24 grams of protein per serving, 75% less saturated fat*, and soy free.
              </p>
              <p>
              *compared to USDA 80% lean/20% fat beef 
              </p>
              <h4>TASTE</h4>
              <p>
              This flavor-packed ground is here to make your meals a little bit tastier. 
              Serve up this lean, plant based protein in some tacos, salads, pastas or even your famous family recipes.
              </p>
            </Content>

            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <b>SKILLET</b>
                  <p>
                  1. Remove meat from packaging and place in a large greased non-stick skillet.
                  </p>
                  <p>
                  2. Cook over medium-high heat, stirring frequently for 8 to 10 minutes or until meat is thoroughly*, breaking apart with a spoon or spatula into desired crumble size.
                  </p>
                  <p>
                  3. Season as desired.
                  </p>
                  <br>
                  </br>
                  <p>
                  Note: Meat must reach a minimum of 165°F with an instant read thermometer in several places. 
                  *Meat will have a reddish-brown color when fully cooked however color is not a reliable indicator of doneness.
                  </p>
                </Collapsable>
                <br />
                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>3 Servings Per Container</p>
                  <p>Serving Size 4 oz. (112g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 290</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2">% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 17g</td>
                      <td>22%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 1.5g</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 4.5g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 8g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 610mg</td>
                      <td>27%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 9g</td>
                      <td>3%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 1g</td>
                      <td>4%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 1g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 0g Added Sugars</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Protein 24g</td>
                      <td>36%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0mcg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Calcium 40mg</td>
                      <td>4%</td>
                    </tr>
                    <tr>
                      <td>Iron 5.2mg</td>
                      <td>30%</td>
                    </tr>
                    <tr>
                      <td>Potassium 860mg</td>
                      <td>20%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *The % Daily Value tells you how much a nutrient in a serving of food contributes to a daily diet.
                        2,000 calories a day is used for general nutrition advice.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"Ingredients"}
                collapsed={true}
              >
                <p>
                WATER, TEXTURED PEA PROTEIN [PEA PROTEIN
                ISOLATE, TAPIOCA STARCH, VITAMIN E (MIXED TOCOPHEROLS)], CANOLA OIL, PEA
                PROTEIN ISOLATE, NATURAL BEEF FAT TYPE FLAVOR (CANOLA OIL, NATURAL FLAVOR,
                SUNFLOWER OIL, MEDIUM CHAIN TRIGLYCERIDES, TRIACETIN), CONTAINS 2% OR LESS:
                CALCIUM SULPHATE DIHYDRATE, CITRIC ACID, DEXTROSE, FRUIT JUICE COLOR,
                GLYCERINE, GRILL FLAVOR FROM SUNFLOWER OIL, GUM ARABIC, MALTODEXTRIN, MODIFIED
                CELLULOSE, MODIFIED CORN STARCH, NATURAL FLAVORS INCLUDING SMOKE FLAVOR, POTATO
                STARCH, PROPYLENE GLYCOL, ROSEMARY EXTRACT, SALT, SEA SALT, SODIUM ALGINATE,
                SUNFLOWER OIL, TETRASODIUM PYROPHOSPHATE, VEGETABLE JUICE COLOR, VINEGAR, YEAST
                EXTRACT.
                </p>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="00053400000347" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
