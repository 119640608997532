import React, { Component } from "react"
import { Collapse, CardBody, Card } from "reactstrap"
import "./Collapse.scss"
import H4 from "../../components/Utility/heading4"
class Collapsable extends Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      collapse:
        this.props.collapsed !== undefined ? !this.props.collapsed : false,
    }
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }))
  }

  render() {
    return (
      <div className={this.props.className}>
        <div
          className={`collapse-button  ${
            this.state.collapse ? "uncollapsed" : "collapsed"
          }`}
          onClick={this.toggle}
          style={{ marginBottom: "1rem" }}
        >
          <H4 headingText2={this.props.text} />
        </div>
        <Collapse isOpen={this.state.collapse}>{this.props.children}</Collapse>
      </div>
    )
  }
}

export default Collapsable
